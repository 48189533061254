import { SEO } from "../../components/SEO";
import "./FoodSystem.css";

export const FoodSystem = () => {
  return (
    <div className="food-system-section">
      <SEO
        title="ENACTS | Food System"
        description="Welcome to the Food System page of ENACTS"
      />
      <h1 className="main-head">What is Food System?</h1>

      {/* Food Defination Section */}
      <div className="food-def">
        <div className="food-text">
          <p>
            The food system is a complex web of activities involving the
            production, processing, transport, and consumption. Issues
            concerning the food system include the governance and economics of
            food production, its sustainability, the degree to which we waste
            food, how food production affects the natural environment and the
            impact of food on individual and population health.
          </p>
        </div>
        <img
          className="food-back"
          src={"/food-system/food-back.svg"}
          alt="food-back"
        />
      </div>

      {/* Food Ecosystem Section */}
      <div className="food-eco-sys">
        <img src={"/food-system/food-eco.svg"} alt="eco-sys-img" />
      </div>

      {/* Food Ecosystem Text Section */}
      <div className="food-eco-txt">
        <p>
          The food system is a complex web of activities involving the
          production, processing, transport, and consumption. Issues concerning
          the food system include the governance and economics of food
          production, its sustainability, the degree to which we waste food, how
          food production affects the natural environment and the impact of food
          on individual and population health.
        </p>
      </div>

      {/* Food Subscribe Section */}
      {/* <div className="food-scbscribe">
        <h1>Subscribe to know more</h1>
        <div className="food-subs-email">
          <input type="text" placeholder="Email Address" />
          <button className="apply-btn">Apply Now</button>
        </div>
      </div> */}
    </div>
  );
};
