import { Link } from "react-router-dom";
import { SEO } from "../../components/SEO";
import teamData from "../../utils/teamList";
import "./About.css";

const tempList = teamData.sort((a, b) => b.name.localeCompare(a.name));

export const About = () => {
  return (
    <div className="about-section">
      <SEO
        title="ENACTS | About"
        description="Welcome to the About page of ENACTS"
      />
      {/* Food System Section */}
      <div className="food-system">
        <div className="food-system-left">
          <h1>ENACTS</h1>
          <img src={"/about/spoon.svg"} alt="spoons" />
        </div>
        <div className="food-system-right">
          <p>
            The traditional emphasis on a calorie-based diet has influenced
            nutrition outcomes over the years, with gradual progress observed in
            improving India’s nutritional metrics. Challenges such as limited
            market systems in remote and hard-to-reach areas have impacted the
            delivery and reach of nutrition support, particularly among
            low-income and marginalized communities. Addressing malnutrition
            requires recognizing its complexity and tackling its interconnected
            variables through collaborative and innovative approaches involving
            diverse stakeholders. The National Agriculture and Nutrition Policy
            (NANP) outlines a convergence agenda to align agriculture and
            nutrition efforts, aiming to enhance nutrition outcomes across the
            country. To support this vision, the Bharti Institute of Public
            Policy at Indian School of Business (ISB) has established, Enabling
            Nutrition-Agriculture Convergence through Technical Support
            (ENACTS). Through multistakeholder partnerships and infusion of
            technological innovations, ENACTS aims to inform NANP and facilitate
            the design of efficient, scalable systems that integrate multiple
            initiatives, harnessing synergy between agriculture and nutrition to
            drive nutrition outcomes.
          </p>
        </div>
      </div>

      {/* Our Story Section */}
      <div className="our-story">
        <img
          className="story-plate"
          src={"/about/story-plate.svg"}
          alt="story-plate"
        />
        <div className="story-text">
          <h1>Envisioning ENACTS: The Giga-map of Nutrition and Food System</h1>
          <p>
            A Giga-map employs a systems design approach to comprehensively
            represent the intricate interconnections, feedback loops, and
            interdependencies within the food system. This approach is rooted in
            systems design thinking, which emphasizes holistic analysis to
            understand the complex relationships among diverse elements such as
            production, distribution, consumption, policy, environment, and
            socio-economic factors. The design process begins by identifying key
            system components and their interactions, followed by mapping these
            elements into layers that reflect ecological, economic, and social
            dimensions. The ENACTS giga-map or we call it “Heureka Quest”
            incorporates dynamic systems modeling to visualize flows of
            resources, information, and energy, highlighting critical leverage
            points and potential areas of systemic risk or inefficiency. By
            integrating multi-disciplinary data and stakeholder perspectives,
            this approach aims to create a visually intuitive yet scientifically
            rigorous tool for decision-making, enabling policymakers and
            researchers to explore interventions that promote resilience,
            equity, and sustainability across the food system.
          </p>
        </div>
        <Link
          to={`${process.env.REACT_APP_REFERENCE_URL}/signin`}
          target="_blank"
        >
          <button className="knw-btn">Know More</button>
        </Link>
        <img
          className="story-back"
          src={"/about/story-back.svg"}
          alt="story-back"
        />
      </div>

      {/* Team Section */}
      <div className="team-section">
        <div className="team-text">
          <h1>Meet Our Team</h1>
          <p>
            We are amazing together. We are a group of change builder for a
            better, sustainable and developed future.
          </p>
        </div>
        <div className="team-profile-container">
          {tempList.map((data, index) => {
            return (
              <div className="profile-card" key={index}>
                <div>
                  <img src={`/${data.image}`} alt="team-portrait" />
                </div>

                <div>
                  <h5>{data.name}</h5>
                  <p>{data.role}</p>
                  <div className="under-stroke" />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Food Subscribe Section */}
      <div className="food-scbscribe">
        <h1>Subscribe to know more</h1>
        <div className="food-subs-email">
          <input type="text" placeholder="Email Address" />
          <button className="apply-btn">Apply Now</button>
        </div>
      </div>

      {/* Opening Section */}
      {/* <div className="opening-section">
        <div className="opening-heading">
          <p>Openings</p>
          <h1>Make a change and join us</h1>
        </div>
        <div className="opening-list">
          <div className="opening-indi">
            <div className="opening-detail">
              <h4>Data Scientist</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis{" "}
              </p>
            </div>
            <button className="apply-btn">Apply Now</button>
          </div>
          <div className="opening-indi">
            <div className="opening-detail">
              <h4>Research Analyst</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis{" "}
              </p>
            </div>
            <button className="apply-btn">Apply Now</button>
          </div>
          <div className="opening-indi">
            <div className="opening-detail">
              <h4>Interaction Designer</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis{" "}
              </p>
            </div>
            <button className="apply-btn">Apply Now</button>
          </div>
        </div>
      </div> */}
    </div>
  );
};
