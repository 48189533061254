const teamData = [{
    name: 'Ashwini Chhatre',
    role: 'Associate Professor & Executive Director',
    image: 'about/team/ashwini_chhatre.jpg'
},
{
    name: 'Abhijeet Parmar',
    role: 'Lead, Technology and Research Partnerships',
    image: 'about/team/abhijeet_parmar.jpg'
},
{
    name: 'Nitisha Jaiswal',
    role: 'Program Coordinator',
    image: 'about/team/nitisha_jaiswal.png'
},
{
    name: 'Niharika Walia',
    role: 'Senior Design Specialist',
    image: 'about/team/niharika_walia.jpg'
},
{
    name: 'Bhumika Morey',
    role: 'GIS Specialist',
    image: 'about/team/bhumika_morey.jpg'
},
{
    name: 'Abhinash Patra',
    role: 'Geospatial Specialist',
    image: 'about/team/abhinash_patra.jpg'
},
{
    name: 'Sanjeev K Kaushal',
    role: 'Lead – Community Ent',
    image: 'about/team/sanjeev_kumar_kaushal.jpg'
},
{
    name: 'Nitesh Saini',
    role: 'Full Stack Developer',
    image: 'about/team/nitesh_saini.jpg'
},
{
    name: 'Swathi G L',
    role: 'Outreach & Communication',
    image: 'about/team/swathi_g_l.jpg'
},
{
    name: 'Siddharth K Yadav',
    role: 'Full Stack Developer',
    image: 'about/team/siddharth_kumar_yadav.png'
},
{
    name: 'Hema Mani D',
    role: 'Senior Project Associate',
    image: 'about/placeholder-female.jpg'
},
{
    name: 'Nutan Satpathy',
    role: 'Project Associate',
    image: 'about/placeholder-male.jpg'
},
{
    name: 'Anveshi Gupta',
    role: 'Project Specialist',
    image: 'about/placeholder-female.jpg'
},
{
    name: 'Ewan N Thomas',
    role: 'Research Associate',
    image: 'about/placeholder-male.jpg'
},
{
    name: 'Siddharth P Valecha',
    role: 'Social Media Specialist',
    image: 'about/placeholder-male.jpg'
},
{
    name: 'Srikanta K Naik',
    role: 'Senior Manager',
    image: 'about/placeholder-female.jpg'
},
{
    name: 'Satyajit Nayak',
    role: 'Project Coordinator',
    image: 'about/placeholder-male.jpg'
},
{
    name: 'Ashwina Mahanti',
    role: 'Project Specialist',
    image: 'about/placeholder-female.jpg'
},
{
    name: 'Pranabandhu Nayak',
    role: 'Project Specialist',
    image: 'about/placeholder-male.jpg'
},
{
    name: 'Pravali P',
    role: 'Senior Project Associate',
    image: 'about/placeholder-female.jpg'
},
{
    name: 'Susmita Kanungo',
    role: 'Project Associate',
    image: 'about/placeholder-female.jpg'
},
{
    name: 'Bilal Shaikh',
    role: 'Software Developer',
    image: 'about/placeholder-male.jpg'
},
{
    name: 'Soumyaa Rao',
    role: 'Project Associate',
    image: 'about/placeholder-female.jpg'
},
{
    name: 'Akanksha B Nayagandhi',
    role: 'Research Associate',
    image: 'about/placeholder-female.jpg'
},
{
    name: 'Santosh K Behera',
    role: 'Project Coordinator',
    image: 'about/placeholder-male.jpg'
},
]


export default teamData;