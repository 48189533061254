import React from "react";
import "./Landing.css"; // Your CSS file for styling
import { SEO } from "../../components/SEO";
import { Link } from "react-router-dom";

const _partners = [
  {
    name: "BIPP",
    image: "landing/1-BIPP-logo.png",
  },
  {
    name: "BMGF",
    image: "landing/2-BMGF-logo.svg",
  },
  {
    name: "IIIT-Delhi",
    image: "landing/3-IIIT-Delhi-logo.png",
  },
  {
    name: "Manipal",
    image: "landing/4-SML-logo.png",
  },
  {
    name: "IIT-Delhi",
    image: "landing/5-IIT-Delhi-logo.png",
  },

  {
    name: "Odisha",
    image: "landing/6-Odisha-seal-logo.svg",
  },
  {
    name: "RPKKV",
    image: "landing/7-RPKKV-logo.png",
  },
  {
    name: "XIM",
    image: "landing/8-XIM-logo.png",
  },
  {
    name: "CoE-AWRDS",
    image: "landing/9-CoE-AWRDS-logo.png",
  },
  {
    name: "LSHTM",
    image: "landing/10-LSHTM-logo.svg",
  },
  {
    name: "MOAI",
    image: "landing/11-MOAI-logo.svg",
  },
  {
    name: "NID",
    image: "landing/12-NID-logo.svg",
  },
  {
    name: "NIN",
    image: "landing/13-NIN-logo.png",
  },
  {
    name: "SPA",
    image: "landing/14-SPA-logo.png",
  },
  {
    name: "UOS",
    image: "landing/15-UOS-logo.png",
  },
];

export const Landing: React.FC = () => {
  return (
    <div className="landing-main-container">
      <SEO
        title="ENACTS | Landing"
        description="Welcome to the landing page of ENACTS"
      />

      {/* Top Section */}
      <div className="landing-top-section">
        <div
          className="heading-container"
          data-aos="zoom-in"
          data-aos-duration="2000"
        >
          <h1>
            Enabling Agriculture-Nutrition Convergence Through Technology
            Support (ENACTS)
          </h1>
          <h4>A Unified Oracle for Nutrition and Food Systems</h4>
        </div>

        <div
          className="haat-head-container"
          data-aos="zoom-in"
          data-aos-duration="2000"
        >
          <div>
            <h1>Haat Census</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi. or incididunt ut labore et dolore magna aliqua. Ut enim ad
              minim veniam, quis nostrud exercitation ullamco laboris nisi.
            </p>
            <button className="knw-btn">Know More</button>
          </div>
        </div>

        <div
          className="image-container"
          data-aos="zoom-in"
          data-aos-duration="2500"
        >
          <img src="/landing/landing_art.svg" alt="Landing" />
        </div>
      </div>

      {/* Partners Section */}
      <div className="landing-partner-section">
        <h1>Friends of ENACTS</h1>
        <div className="marquee">
          <div className="marquee-content">
            {_partners.map((data, index) => (
              <div className="partner-logo" key={index}>
                <img src={`/${data.image}`} alt={data.name} />
              </div>
            ))}
            {_partners.map((data, index) => (
              <div className="partner-logo" key={`duplicate-${index}`}>
                <img src={`/${data.image}`} alt={data.name} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Happenings Section */}
      <div className="landing-happ-section">
        <div className="happ-txt-container">
          <h1>Happenings at ENACTS</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi.
          </p>
        </div>

        <div className="happ-detail-container">
          <div className="happ-video-container">
            <video
              src="/landing/videos/Drone_Mapping.mp4"
              muted
              autoPlay
              loop
            />
          </div>
          <div className="happ-video-txt-container">
            <div>
              <h1>Drone Mapping,</h1>
              <h6>Harichandanpur, Odisha</h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi.
              </p>
              <Link to="https://www.noeawrds.net" target="_blank">
                <button className="knw-btn">Know More</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
