import { SEO } from "../../components/SEO";
import "./ContactUs.css";

export const ContactUs = () => {
  return (
    <div className="contact-main-section">
      <SEO
        title="ENACTS | Contact Us"
        description="Welcome to the Contact Us page of ENACTS"
      />
      {/* Contact Top Section */}
      <div className="contact-top">
        <div className="contact-text">
          <h1>Get In Touch</h1>
          <p>
            We are amazing together. We are a group of change builder for a
            better, sustainable and developed future.
          </p>
        </div>

        <img
          className="contact-map"
          src={"/contact/contact-map.svg"}
          alt="contact-map"
        />
        <img
          className="contact-back"
          src={"/contact/contact-back.svg"}
          alt="contact-back"
        />
      </div>

      {/* Contact Form Section */}
      <div className="contact-form">
        <div className="contact-left">
          <div className="contact-form-container">
            <input type="text" placeholder="Full Name" />
            <input type="text" placeholder="Email Address" />
            <textarea type="text" rows={3} placeholder="Message For Us" />
            <button className="send-btn">Send</button>
          </div>
        </div>
        <div className="contact-right">
          <div className="detail-container">
            <div className="cont-row">
              <img src={"/contact/location.svg"} alt="loc-cont" />
              <a
                href="https://www.google.com/maps?q=ISB,+Gachibowli,+Hyderabad,+Telangana+500111"
                target="_blank"
                rel="noopener noreferrer"
              >
                ISB, Gachibowli, Hyderabad, Telangana 500111
              </a>
            </div>
            <div className="cont-row">
              <img src={"/contact/phone.svg"} alt="phone-cont" />
              <a href="tel:04023007000">040-2300-7000</a>
            </div>
            <div className="cont-row">
              <img src={"/contact/web.svg"} alt="web-cont" />
              <a
                href="https://www.isb.edu"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.isb.edu
              </a>
            </div>
            <div className="cont-row">
              <img src={"/contact/mail.svg"} alt="mail-cont" />
              <a href="mailto:enactsfoodsystem@gmail.com">
                enactsfoodsystem@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="side-plates">
        <img src={"/contact/contact-plates.svg"} alt="contact-plates" />
      </div>
    </div>
  );
};
