import { Link } from "react-router-dom";
import { SEO } from "../../components/SEO";
import "./TermsCondition.css";

export const TermsCondition = () => {
  return (
    <div className="tc-main-section">
      <SEO
        title="ENACTS | Terms & Conditions"
        description="Welcome to the Terms & Conditions page of ENACTS"
      />
      <div className="tc-detail-info">
        <h1>Terms & Conditions</h1>
        <p>
          Welcome to ENACTS! By using our website, you agree to these Terms and
          Conditions. Please read them carefully.
        </p>

        <strong>1. Use of the Website</strong>
        <ul>
          <li>
            You may use this website solely for lawful purposes and in
            compliance with these Terms.
          </li>
          <li>
            While the website aims to foster collaboration and knowledge
            sharing, it does not currently provide access to the source code or
            any open-source repositories.
          </li>
        </ul>

        <strong>2. Intellectual Property</strong>
        <ul>
          <li>
            All content on this website, including text, graphics, and other
            materials, is the property of ENACTS unless otherwise stated.
          </li>
          <li>Any unauthorized use of the website's content is prohibited.</li>
        </ul>

        <strong>3. User Contributions</strong>
        <ul>
          <li>
            If the website allows user submissions, you grant ENACTS a
            non-exclusive, royalty-free license to use, distribute, and modify
            your submissions in connection with the website's goals.
          </li>
          <li>
            You confirm that your contributions do not infringe on the
            intellectual property rights of others.
          </li>
        </ul>

        <strong>4. Limitation of Liability</strong>
        <ul>
          <li>
            ENACTS shall not be held liable for any damages arising from the use
            of this website, including but not limited to direct, indirect, or
            consequential damages.
          </li>
        </ul>

        <strong>5. Privacy</strong>
        <ul>
          <li>
            We respect your privacy. Please refer to our{" "}
            <Link to="/privacy-policy">Privacy Policy</Link> for details on how
            your information is collected and used.
          </li>
        </ul>

        <strong>6. Modifications to Terms</strong>
        <ul>
          <li>
            We reserve the right to update these Terms at any time. Changes will
            be posted on this page with an updated "Last Updated" date.
          </li>
        </ul>

        <strong>7. Governing Law</strong>
        <ul>
          <li>
            These Terms and Conditions are governed by the laws of India. Any
            disputes arising from or in connection with these Terms will be
            subject to the exclusive jurisdiction of the courts of Hyderabad.
          </li>
        </ul>
      </div>
    </div>
  );
};
